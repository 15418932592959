import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'


const options = {
  renderMark: {
    [MARKS.CODE]: code =>{ 
      const cmd = code.slice(1,-1).split('$')
      switch (cmd[0]) {
        case "mailto":
          return  <u>
                    <a 
                      href={`mailto:${cmd[1]}`}
                      aria-label={`email to ${cmd[1]}`}
                    >
                      {cmd[1]}
                    </a>
                  </u>
        case "telephone":
          return  <u>
                    <a 
                      href={`tel:${cmd[1]}`}
                      aria-label={`call us at ${cmd[1]}`}
                    >
                      {cmd[1]}
                    </a>
                  </u>
        case "externalLink":
          return  <u>
                    <a 
                      href={cmd[1]}
                      target='_blank'
                      rel='noopener'
                      aria-label={cmd[1]}
                    >
                      {cmd[1]}
                    </a>
                  </u>
      }
    }
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: ({
      data: {
        target: {
          fields: { file, title },
        },
      },
    }) => {
      return <img alt={title['en']} src={file['en'].url} />
    },
  },
}

const RichText = ({ json }: { json?: string }) => {
  //console.log(json)
  //@ts-ignore
  return documentToReactComponents(json, options) 

}

export default RichText
